<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <b-card title="Deniers scans effectués">

    <!-- table -->

    <b-button
        href='/scan/add'
        variant="primary"
      >
        + Ajouter un scan
      </b-button>
<br /><br />

    <vue-good-table
      :columns="columns"
      :rows="scans"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
    
      <template v-if="props.row.user!=null"
        slot="table-row"
        slot-scope="props"
        
      >
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <center>
           
            <span class="text-nowrap">{{props.row.name}}</span>
          </center>
        </span>
        <span v-else-if="props.column.field == 'user.name' " >
          <center>
            {{ props.row.user.name }} {{ props.row.user.surname }}<br />

            <span :style="'font-size:10px;'">
              ({{props.row.user.scans_number}} scan<span v-if="props.row.user.scans_number>1">s</span> effectué<span v-if="props.row.user.scans_number>1">s</span>)
            </span>
            <br /><a :style="'text-decoration: underline;'" @click="$router.push({name:'scanDetail', params: { id: props.row.id }})">
              Voir le scan
            </a>
          </center>
        </span>
        <span v-else-if="props.column.field == 'action'">
          <span>
            <b-button v-if="selectedScan && selectedScan==props.row.id"
              
              variant="success"
            >
              Scan sélectionné
            </b-button>
            <b-button v-else @click="selectScan(props.row.id)"
              
              variant="dark"
            >
              Sélectionner Scan
            </b-button>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              
          
            <b-dropdown-item  @click="$router.push({name:'editScan', params: { id: props.row.id }})">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Modifier utilisateur</span>
              </b-dropdown-item>
              <b-dropdown-item  v-b-modal.modal-danger @click="selectedItem=props.row.id">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Page 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> sur {{ props.total }} résultats </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="totalRows"
              v-model="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
        <b-modal
          id="modal-danger"
          cancel-variant="outline-secondary"
          cancel-title="Non"
          ok-variant="danger"
          ok-title="Oui"
          @ok="deleteScan(selectedItem)"
          modal-class="modal-danger"
          centered
          title="Êtes-vous sûr ?"
        >
          <b-card-text>
            Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer ce scan ?
          </b-card-text>
        </b-modal>
      </template>
    </vue-good-table>

  </b-card>
</b-overlay>
</template>

<script>
import {BModal, BButton, BOverlay, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BModal,
    BButton
  },
  data() {
    return {
      scans:[],
      selectedScan:localStorage.senseye_b2b_selected_scan,
      pageLength: 10,
      totalRows:0,
      currentPage:1,
      dir: false,
      loading:true,
      selectedItem:null,
      columns: [
        {
          label: 'Nom',
          field: 'name',
        },
        {
          label: 'Date',
          field: 'date',
          type:'date',
          dateInputFormat:'dd/MM/yyyy',
          formatFn: function (value) {
              return value != null ? value : null
          }
        },
        {
          label: 'Appareil',
          field: 'device_model',
        },
        {
          label: 'Compte client',
          field: 'user.name',
        },
         {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',

    }
  },
  watch:{
    currentPage:async function(){
      this.loading=true
      let offset=this.currentPage*this.pageLength

      
      let scans=await APIRequest.list('scans', null, null, this.pageLength,offset,null,null,null,null,localStorage.id_user_senseye_auth,null,'desc')
      this.scans=scans[0]
      this.loading=false
    }
  },
  methods:{
    selectScan(id) {
      this.selectedScan=id
      localStorage.senseye_b2b_selected_scan=id
     
    },
    async deleteScan(id)
    {
      this.loading=true
      try {
        await APIRequest.delete('scans',id)
        let scans=await APIRequest.list('scans', null, null, this.pageLength,offset,null,null,null,null,localStorage.id_user_senseye_auth,null,'desc')
        this.scans=scans[0]
        this.totalRows--
        this.loading=false
        if(this.selectedScan) {
          if(this.selectedScan.id==id) {
            localStorage.senseye_b2b_selected_scan=null
          }
        } 
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Le scan a été supprimé !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    }
  },
  async mounted()
  {
    this.loading=true
    let scans=await APIRequest.list('scans', null, null, 1000,null,null,null,null,null,localStorage.id_user_senseye_auth,null,'desc')
    this.scans=scans[0]
    this.totalRows=scans[0].length
    console.log('total rows')
    console.log(scans[1])
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
